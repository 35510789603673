exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-conservatories-js": () => import("./../../../src/pages/conservatories.js" /* webpackChunkName: "component---src-pages-conservatories-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-garages-js": () => import("./../../../src/pages/garages.js" /* webpackChunkName: "component---src-pages-garages-js" */),
  "component---src-pages-garden-rooms-js": () => import("./../../../src/pages/garden-rooms.js" /* webpackChunkName: "component---src-pages-garden-rooms-js" */),
  "component---src-pages-greenhouses-js": () => import("./../../../src/pages/greenhouses.js" /* webpackChunkName: "component---src-pages-greenhouses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metal-sheds-js": () => import("./../../../src/pages/metal-sheds.js" /* webpackChunkName: "component---src-pages-metal-sheds-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-summerhouses-js": () => import("./../../../src/pages/summerhouses.js" /* webpackChunkName: "component---src-pages-summerhouses-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-timber-sheds-js": () => import("./../../../src/pages/timber-sheds.js" /* webpackChunkName: "component---src-pages-timber-sheds-js" */)
}

